import React, { useState, useRef, useEffect } from "react";
import { graphql, Link } from "gatsby";
import * as styles from "../styles/menu/mainMenu.module.css";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import MenuBrowse from "../components/menu/MenuBrowse";
import FloatButton from "../components/menu/FloatButton";
import ProductList from "../components/menu/ProductList";

const Card = ({ product, index }) => {
  const cardRef = useRef();
  return (
    <React.Fragment>
      <Link to={`/${product.slug}`}>
        <div
          id={`${product.name.replace(/\s/g, "")}`}
          ref={cardRef}
          key={index}
          className={styles.card}
        >
          <GatsbyImage
            className={styles.cardImg}
            image={getImage(product.img)}
            alt=""
          />
          <div className={styles.cardInfo}>
            <h5 key={index}>{product.name}</h5>
            <div className={styles.divider} />
            <p>{product.description}</p>
            <div className={styles.buttonContainer}>
              <button className={`${styles.button}`}>Open</button>
            </div>
          </div>
        </div>
      </Link>
    </React.Fragment>
  );
};

function Products({ data }) {
  const [menu, setMenu] = useState({ totalItems: 0 });
  const [filteredData, setFilteredData] = useState([]);
  //Get FilteredProducts
  useEffect(() => {
    const formattedData = [];
    //Get all the meal courses and their respective ID's
    data.allStrapiMealCourses.edges.forEach((edge, index) => {
      formattedData.push({
        courseName: edge.node.name,
        courseId: edge.node.strapiId,
        menuInfo: [],
      });
    });
    //Match each MenuCategory with its respective Meal Course and include its product info
    data.allStrapiMenuCategory.edges.forEach((edge) => {
      formattedData.forEach((item) => {
        if (item.courseId === edge.node.meal_course.id) {
          item["menuInfo"] = [
            ...item["menuInfo"],
            {
              menuCategory: edge.node.name,
              products: edge.node.products,
            },
          ];
        }
      });
    });
    setFilteredData(formattedData);
  }, []);
  const [floatShow, setFloatShow] = useState(true);
  //To mount and unmount the different menuBrowse helpers
  useEffect(() => {
    window.innerWidth <= 992 ? setFloatShow(true) : setFloatShow(false);
    const handleResize = () => {
      window.innerWidth <= 992 ? setFloatShow(true) : setFloatShow(false);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Layout
      title={data.strapiSeo.PageTitle}
      description={data.strapiSeo.PageDescription}
    >
      {floatShow && (
        <div className={styles.options}>
          <FloatButton
            menuSelectCloseCard={true}
            name="browse menu"
            childProps={{ data: filteredData }}
            Child={MenuBrowse}
          />
        </div>
      )}

      <div className={styles.container}>
        {!floatShow && (
          <div className={styles.sideHelper}>
            <MenuBrowse data={filteredData} />
          </div>
        )}

        <ProductList
          className={styles.productList}
          filteredData={filteredData}
          Card={Card}
          cardProps={{ setMenu: setMenu, menu: menu }}
        />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query products {
    allStrapiMealCourses {
      edges {
        node {
          strapiId
          name
          id
          menu_categories {
            id
          }
        }
      }
    }
    allStrapiMenuCategory {
      edges {
        node {
          name
          meal_course {
            id
          }
          products {
            slug
            name
            description
            img {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 350)
              }
            }
          }
        }
      }
    }
    allStrapiProduct {
      edges {
        node {
          strapiId
          name
        }
      }
    }
    strapiSeo(NameOfPage: { eq: "products" }) {
      PageDescription
      PageTitle
    }
  }
`;

export default Products;
